<template>
  <v-row class="signup ma-0">
    <v-col>
      <v-card v-if="card" elevation="5" max-width="1070" class="mx-auto d-flex">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pa-lg-10 pa-md-5 pt-5 ma-auto d-sm-block d-none">
            <v-img class="rounded-l ma-auto img" cover src="@/assets/img/auth.svg"></v-img>
          </v-col>
          <v-col cols="12" md="6" class="pa-lg-10 pa-sm-10 pa-5 pt-sm-4">
            <v-card-title class="pa-0 justify-center">
              <div class="font-weight-bold text-h5 secondary--text mb-2">SIGN UP</div>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-form @submit.prevent="action" ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" class="py-sm-3 py-0">
                    <v-text-field
                      v-model="firstname"
                      label="First Name"
                      :error-messages="firstnameErrors"
                      filled
                      outlined
                      dense
                      required
                      color="input"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-sm-3 pt-0">
                    <v-text-field
                      v-model="lastname"
                      label="Last Name"
                      :error-messages="lastnameErrors"
                      filled
                      outlined
                      dense
                      required
                      color="input"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  class="mt-n1"
                  v-model="company"
                  label="Company name"
                  :error-messages="companyErrors"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                ></v-text-field>
                <v-autocomplete
                  class="mt-n1"
                  v-model="country"
                  :items="countryList"
                  label="Country"
                  :error-messages="countryErrors"
                  filled
                  outlined
                  dense
                  required
                  item-text="name"
                  item-value="id"
                  color="input"
                ></v-autocomplete>
                <v-text-field
                  class="mt-n1"
                  v-model="email"
                  label="Email"
                  :error-messages="emailErrors"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                ></v-text-field>
                <v-text-field
                  class="mt-n1"
                  v-model="password"
                  label="Password"
                  :error-messages="passwordErrors"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  hint="Please use at least one uppercase letter, one digit, and one of the allowed symbols: ~!@#$%^&*()_+-=|:<>?,."
                  persistent-hint
                  filled
                  outlined
                  dense
                  required
                  color="input"
                ></v-text-field>
                <v-checkbox hide-details class="mt-0" v-model="privacy" color="success">
                  <template v-slot:label>
                    <div class="text-body-1 secondary--text mt-1">
                      Agree to
                      <router-link to="/terms-of-service" class="font-weight-medium secondary--text">Terms of Service</router-link>
                      and <router-link to="/privacy-policy" class="font-weight-medium secondary--text">Privacy policy</router-link>
                    </div>
                  </template>
                </v-checkbox>
                <div class="text-center mt-4">
                  <v-btn :disabled="!privacy" type="submit" max-width="300" width="100%" height="48" color="primary">Sign Up</v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-else elevation="5" max-width="580" class="mx-auto px-sm-16 py-sm-13 pa-5">
        <v-img class="mx-auto" max-width="162" src="@/assets/img/msg.svg"></v-img>
        <div class="text-h5 text-center font-weight-bold mt-10">Confirm your email address!</div>
        <p class="text-center opasity--text mt-3">
          The confirmation email has been sent to email {{ email }}. Check your Inbox and click on the “Confirm my email” link.
        </p>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    firstname: { required },
    lastname: { required },
    company: { required },
    country: { required },
    email: { required, email },
    password: { required, minLength: minLength(6) },
  },
  data() {
    return {
      card: true,
      show: false,
      firstname: null,
      lastname: null,
      company: null,
      country: null,
      email: '',
      password: null,
      privacy: false,
      status: null,
      error: null,
    };
  },
  mounted() {
    this.$store.dispatch('getCoutries');
  },
  computed: {
    countryList() {
      return this.$store.getters.countries;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.firstname.$dirty) {
        return errors;
      }
      !this.$v.firstname.required && errors.push('Please enter your first name');
      this.error == 'first_name__invalid' && errors.push('Provided first name is not valid');
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.lastname.$dirty) {
        return errors;
      }
      !this.$v.lastname.required && errors.push('Please enter your last name');
      this.error == 'last_name__invalid' && errors.push('Provided last name is not valid');
      return errors;
    },
    companyErrors() {
      const errors = [];
      if (!this.$v.company.$dirty) {
        return errors;
      }
      !this.$v.company.required && errors.push('Please enter your company name');
      this.error == 'company_name__invalid' && errors.push('Provided company name is not valid');
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.country.$dirty) {
        return errors;
      }
      !this.$v.country.required && errors.push('Country is required');
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }
      !this.$v.email.email && errors.push('Provided email address is not valid');
      !this.$v.email.required && errors.push('Please enter your email address');
      this.error == 'email__invalid' && errors.push('Provided email address is not valid');
      this.error == 'email__exists' && errors.push('Provided email address is already registered. Please use another one');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) {
        return errors;
      }
      !this.$v.password.required && errors.push('Please enter your password');
      !this.$v.password.minValue && errors.push('Password must be at least 6 characters');
      this.error == 'password__invalid' &&
        errors.push('Please use at least one uppercase letter, one digit, and one of the allowed symbols: ~!@#$%^&*()_+-=|:<>?,.');
      return errors;
    },
  },
  methods: {
    async action() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.error = null;
        const data = {
          first_name: this.firstname,
          last_name: this.lastname,
          email: this.email,
          password: this.password,
          company_name: this.company,
          country_id: this.country,
        };
        await this.$store
          .dispatch('signUp', data)
          .then((response) => {
            this.card = !this.card;
            this.status = response.status;
            sessionStorage.setItem('jwt_token', response.data.access_token);
          })
          .catch((e) => {
            this.status = e.response.status;
            this.error = e.response.data.error[0];
          });
      }
    },
  },
  destroyed() {
    this.$store.dispatch('setCoutries', []);
  },
};
</script>

<style>
.signup .img {
  max-width: 490px;
}
@media screen and (max-width: 959px) {
  .signup .img {
    max-width: 270px;
  }
}
</style>
